<template>
  <div class="grid">
    <Toast />
    <div class="col-12">
      <div class="card">
        <h5>Datos de la cuenta</h5>
        <div class="grid text-center justify-content-center">
          <div class="md:col-4 xs:col-12 chip-block">
            <p>Plan actual</p>
            <p :class="[isTrialAccount ? 'trial-chip' : 'chip']">
              {{ user.pricingType }}
            </p>
            <Button @click="navigateToPricing">Gestionar plan</Button>
          </div>
          <div class="md:col-4 xs:col-6 chip-block">
            <p>SMS restantes</p>
            <p class="chip">{{ user.smsCredit }}</p>
            <Button @click="navigateToBuySms">Añadir más</Button>
          </div>
          <div class="md:col-4 xs:col-6 chip-block">
            <p>Número máximo de puntos de venta</p>
            <p class="chip">{{ user?.maxOrganizations }}</p>
            <Button @click="navigateToPricing">Añadir más</Button>
          </div>
        </div>
      </div>
      <div class="card">
        <h5>Configuración de la cuenta</h5>
        <div class="grid">
          <div class="col-12">
            <div class="field-checkbox mb-0">
              <Checkbox
                id="smsAlert"
                name="option"
                v-model="disableSmsAlerts"
                :binary="true"
              />
              <label for="smsAlert">No mostrar alerta de créditos de SMS cuando estén a punto de agotarse</label>
            </div>
          </div>
          <div class="col-12 mt-1 flex justify-content-end">
            <Button @click="saveConfig">Guardar</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PRICING_TYPES from '../constants/pricingTypes';
import api from '../api/Acutame';

export default {
  data() {
    const disableSmsAlerts = this.$store.state.user.disableSmsAlerts;
    return {
      user: this.$store.state.user,
      disableSmsAlerts,
    };
  },
  methods: {
    navigateToPricing() {
      this.$router.push("/pricing");
    },
    navigateToBuySms() {
      this.$router.push("/pricing/sms");
    },
    async saveConfig() {
      const newConfig = { disableSmsAlerts: this.disableSmsAlerts };
      const user = await api.updateConfig(newConfig);
      this.$toast.add({severity:'success', summary: 'Configuración guardada', life: 3000});
      this.$store.commit('setUser', { user });
    },
  },
  computed: {
    isTrialAccount() {
      return this.user.pricingType === PRICING_TYPES.TRIAL;
    },
    isProAccount() {
      return this.user.pricingType === PRICING_TYPES.PRO;
    },
  },
};
</script>

<style scoped>
.chip-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chip {
  width: fit-content;
  padding: 1em 1.5em;
  border-radius: 100%;
  background-color: lightblue;
  color: white;
}

.trial-chip {
  width: fit-content;
  padding: 1em 1.5em;
  border-radius: 100%;
  background-color: sandybrown;
  color: black;
}
</style>
